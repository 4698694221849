.logo1{
 width: 15%;   
 height: 5%;
}
.logo2{
    width: 20%;   
    height: 13%;
   }
.titulo{

    align-content: center;
    text-align: center;
}
.responsive-text {
    font-size: 3.2rem; /* Tamaño inicial del texto */
    align-content: center;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .responsive-text {
      font-size: 1.8rem; /* Tamaño del texto para pantallas más pequeñas */
      align-content: center;
      text-align: center;
    }
  }
  
  @media (max-width: 576px) {
    .responsive-text {
      font-size: 1.3rem; /* Tamaño del texto para pantallas aún más pequeñas */
      align-content: center;
      text-align: center;
    }

  }